import styled from '@emotion/styled'
import { Facebook, Instagram, Youtube } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { compact, uniqBy } from 'lodash'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  address?: string
  cin?: string
  cir?: string
  email?: string
  emailBooking?: string
  emailBookingGroups?: string
  facebookURL?: string
  instagramURL?: string
  youtubeURL?: string
  languageCode: string
  languagePrefix?: string | null
  logo?: string
  logoFamily?: string
  logoTripadvisor?: string
  phone?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  siteName?: string
  vat?: string
  whatsappNumber?: string
  whatsappURL?: string
}

export const Footer = memo(function Footer({
  address,
  cin,
  cir,
  email,
  emailBooking,
  emailBookingGroups,
  facebookURL,
  instagramURL,
  youtubeURL,
  languageCode,
  logo,
  logoFamily,
  logoTripadvisor,
  phone,
  policyMenu,
  siteName,
  vat,
  whatsappNumber,
  whatsappURL,
}: Props) {
  const policyNav = compact(
    compact(policyMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head row wrap space="between">
          <Logos>
            {logo ? (
              <Logo src={logo} alt={siteName} width="246" height="86" />
            ) : null}
            {logoFamily ? (
              <Media greaterThanOrEqual="desktopSmall">
                <Logo
                  src={logoFamily}
                  alt="Family Owned Hotels"
                  width="125"
                  height="66"
                />
              </Media>
            ) : null}
          </Logos>

          <Contacts>
            {address ? (
              <>
                <Media greaterThanOrEqual="desktopSmall">
                  <Address>{address}</Address>
                </Media>
                <Media lessThan="desktopSmall">
                  <Address>{address.replace('-', '')}</Address>
                </Media>
              </>
            ) : null}

            <Wrap>
              {phone ? (
                <Phone
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })

                      typeof window._paq !== 'undefined' &&
                        window._paq.push([
                          'trackEvent',
                          'Website',
                          'Click',
                          'Phone Number',
                        ])
                    }
                  }}
                >
                  {`${useVocabularyData('telephone', languageCode)}: `}
                  <Link>{phone}</Link>
                </Phone>
              ) : null}

              {whatsappURL ? (
                <>
                  <Separator />

                  <Phone href={whatsappURL}>
                    {`${useVocabularyData('whatsapp', languageCode)}: `}
                    <Link>{whatsappNumber}</Link>
                  </Phone>
                </>
              ) : null}
            </Wrap>

            <Wrap>
              {email ? (
                <Email
                  href={`mailto:${email}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })

                    typeof window._paq !== 'undefined' &&
                      window._paq.push([
                        'trackEvent',
                        'Website',
                        'Click',
                        'Email Address',
                      ])
                  }}
                >
                  {`${useVocabularyData('info', languageCode)}: `}
                  <Link>{email}</Link>
                </Email>
              ) : null}

              {emailBooking ? (
                <>
                  <Separator />

                  <Email
                    href={`mailto:${emailBooking}`}
                    onClick={() => {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'Website',
                          event_label: 'Email Address',
                        })

                      typeof window._paq !== 'undefined' &&
                        window._paq.push([
                          'trackEvent',
                          'Website',
                          'Click',
                          'Email Address',
                        ])
                    }}
                  >
                    {`${useVocabularyData('reservations', languageCode)}: `}
                    <Link>{emailBooking}</Link>
                  </Email>
                </>
              ) : null}

              {emailBookingGroups ? (
                <>
                  <Separator />

                  <Email
                    href={`mailto:${emailBookingGroups}`}
                    onClick={() => {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'Website',
                          event_label: 'Email Address',
                        })

                      typeof window._paq !== 'undefined' &&
                        window._paq.push([
                          'trackEvent',
                          'Website',
                          'Click',
                          'Email Address',
                        ])
                    }}
                  >
                    {`${useVocabularyData(
                      'groups-reservations',
                      languageCode,
                    )}: `}
                    <Link>{emailBookingGroups}</Link>
                  </Email>
                </>
              ) : null}
            </Wrap>
          </Contacts>

          <Social dial={6} row>
            {instagramURL ? (
              <SocialItem
                aria-label="Instagram"
                href={instagramURL}
                rel="noreferrer"
                target="_blank"
              >
                <Instagram />
              </SocialItem>
            ) : null}
            {facebookURL ? (
              <SocialItem
                aria-label="Facebook"
                href={facebookURL}
                rel="noreferrer"
                target="_blank"
              >
                <Facebook />
              </SocialItem>
            ) : null}
            {youtubeURL ? (
              <SocialItem
                aria-label="Youtube"
                href={youtubeURL}
                rel="noreferrer"
                target="_blank"
              >
                <Youtube />
              </SocialItem>
            ) : null}
          </Social>

          {logoFamily ? (
            <Media lessThan="desktopSmall">
              <Logo
                src={logoFamily}
                alt="Family Owned Hotels"
                width="125"
                height="66"
              />
            </Media>
          ) : null}
        </Head>

        <Bottom>
          {policyMenu ? (
            <Policy row wrap>
              <>
                {uniqBy(policyNav, 'title').map((item, index) => (
                  <MenuItem key={index} {...item} />
                ))}
                <MenuItem
                  title={useVocabularyData('credits', languageCode)}
                  URL="https://www.qnt.it"
                />
              </>
            </Policy>
          ) : null}
          {vat ? (
            <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
          ) : null}
          {cin ? (
            <Cin>{`${useVocabularyData('cin', languageCode)} ${cin}`}</Cin>
          ) : null}
          {cir ? (
            <Cir>{`${useVocabularyData('cir', languageCode)} ${cir}`}</Cir>
          ) : null}
          {siteName ? (
            <SiteName>
              © {new Date().getFullYear()} Giolli Nazionale SRL
            </SiteName>
          ) : null}
          {logoTripadvisor ? (
            <TripAdvisor
              src={logoTripadvisor}
              alt="Tripasvisor"
              width="108"
              height="130"
            />
          ) : null}
        </Bottom>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding: 5.5rem 5.833vw 2.5rem;
  position: relative;

  @media (max-width: 1023px) {
    padding: 5rem 6.039vw 2.8125rem;
  }
`

const Head = styled(FlexBox)`
  > div {
    width: 33.333%;

    @media (max-width: 1023px) {
      width: 100%;
      text-align: center;
    }
  }
`

const Logos = styled.div`
  .fresnel-container {
    margin-top: 2.8125rem;
  }
`

const Logo = styled.img`
  display: block;

  @media (max-width: 1023px) {
    margin: 0 auto;
  }
`

const Contacts = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 5rem;
  }
`

const Wrap = styled.div`
  margin-bottom: 1.875rem;
`

const Separator = styled.div``

const Address = styled.div`
  margin-bottom: 1.875rem;

  @media (max-width: 1023px) {
    max-width: 13.75rem;
    margin: 0 auto 2.75rem;
  }
`

const Phone = styled.a``

const Link = styled.span`
  text-decoration: underline;
`

const Email = styled.a``

const Social = styled(FlexBox)`
  @media (max-width: 1023px) {
    justify-content: center;
    margin: 3.75rem auto;
  }
`

const SocialItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  border: 4px solid ${rgba(theme.colors.variants.neutralLight3, 0.2)};
  border-radius: 50%;
  margin-right: 1.25rem;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    border-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  }
  &:last-of-type {
    margin-right: 0;
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
  }

  @media (max-width: 1023px) {
    margin: 0 0.625rem;
  }
`

const Bottom = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: 7.5rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`

const Policy = styled(FlexBox)`
  margin-bottom: 0.75rem;

  @media (max-width: 1023px) {
    justify-content: center;
  }
`

const Vat = styled.span`
  margin-right: 1.5rem;

  @media (max-width: 1023px) {
    display: block;
    margin: 1.25rem auto 0;
    text-align: center;
  }
`

const Cin = styled.span`
  margin-right: 1.5rem;

  @media (max-width: 1023px) {
    display: block;
    margin: 0.5rem auto 0;
    text-align: center;
  }
`

const Cir = styled.span`
  margin-right: 1.5rem;

  @media (max-width: 1023px) {
    display: block;
    margin: 0.5rem auto 0;
    text-align: center;
  }
`

const SiteName = styled.span`
  @media (max-width: 1023px) {
    display: block;
    margin: 0.5rem auto 0;
    text-align: center;
  }
`

const TripAdvisor = styled.img`
  position: absolute;
  bottom: 2.5rem;
  right: 5.833vw;

  @media (max-width: 1023px) {
    display: block;
    margin: 2.75rem auto 0;
    position: relative;
    bottom: auto;
    right: auto;
  }
`
